import React from 'react';
import block from 'bem-cn-lite';
import './BasketItems.scss';
import {Header, Items} from './components';
import {BasketItemModel} from '@features/basket/interfaces';

const b = block('basket-items');

export interface BasketItemsProps {
    items?: BasketItemModel[];
}

export const BasketItems: React.FC<BasketItemsProps> = ({ items }) => {
    return (
        <div className={b()}>
            <Header />
            <Items items={items} />
        </div>
    );
};