import React from 'react';
import block from 'bem-cn-lite';
import dateFormat from 'dateformat';
import './DeliveryDate.scss';

const b = block('delivery-date');

export interface DeliveryDateProps {
    value: Date;
    className: string;
}

export const DeliveryDate: React.FC<DeliveryDateProps> = (props) => {
    return <div className={b() + ' ' + props.className}>{dateFormat(props.value, 'dd.mm.yy')}</div>;
};
