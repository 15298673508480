import React, {useState} from 'react';
import block from 'bem-cn-lite';
import {Item, SectionHeader, SectionItems, SectionItemsHeader} from './components';
import {SectionItemModel, SortOption} from '@features/search/interfaces';
import './Section.scss';

const b = block('section');

interface SectionProps {
    title: string;
    showSectionHeader: boolean;
    items: SectionItemModel[];
    sortOptions: SortOption[];
    onSortChanged?: (sortOption: SortOption) => void;
}

export const Section: React.FC<SectionProps> = ({
    title,
    showSectionHeader,
    items,
    sortOptions,
    onSortChanged,
}) => {
    function handleSortChanged(sortOption: SortOption) {
        if (onSortChanged) onSortChanged(sortOption);
    }

    const [isHidden, setIsHidden] = useState(false);
    const isTopLevel = showSectionHeader; // показывает прогресс бар поиска только в заглавной таблице

    function toggleVisibility() {
        setIsHidden((prev) => !prev);
    }

    return items.length >= 1 ?
        (<div className={b({hidden: isHidden})}>
            <SectionHeader
                title={title}
                isHidden={isHidden}
                onToggleVisibility={toggleVisibility}
                isTopLevel={isTopLevel}
            />
            <SectionItems hiddenSection={isHidden}>
                <SectionItemsHeader
                    showSectionHeader={showSectionHeader}
                    sortOptions={sortOptions}
                    onSortChanged={handleSortChanged}
                />
                {items.map((item) => {
                    return (
                        <Item key={item.key} header={item.header} proposals={item.proposals}></Item>
                    );
                })}
            </SectionItems>
        </div>) : null;
};
