import {AppState} from './context';
import {userFromToken, userStorage} from './user';
import {tokenStorage} from './token';
import {countersStorage} from './counters';

export type EventName = 'login' | 'logout' | 'increment' | 'clear_cnt';

export const createEmitter = (setState: React.Dispatch<React.SetStateAction<AppState>>) => {
    return (name: EventName, event?: object) => {
        switch (name) {
            case 'logout':
                emitLogout(setState);
                break;
            case 'login':
                emitLogin(setState, event as LoginEvent);
                break;
            case 'increment':
                emitCounter(setState, event as CounterEvent);
                break;
            case 'clear_cnt':
                emitCounterClear(setState, event as CounterEvent);
                break;
        }
    };
};

function emitLogout(setState: React.Dispatch<React.SetStateAction<AppState>>) {
    userStorage.clear();
    tokenStorage.clear();

    setState((prevState: AppState) => ({
        ...prevState,
        ...{
            user: undefined,
            token: undefined,
        },
    }));
}

interface LoginEvent {
    token: string;
}

function emitLogin(setState: React.Dispatch<React.SetStateAction<AppState>>, event: LoginEvent) {
    userStorage.clear();
    tokenStorage.clear();
    countersStorage.clear();

    tokenStorage.set(event.token);
    userStorage.set(userFromToken(event.token));
    countersStorage.set({});

    setState((prevState: AppState) => ({
        ...prevState,
        ...{
            user: userStorage.get()!,
            token: tokenStorage.get()!,
            counters: countersStorage.get()!,
        },
    }));
}

interface CounterEvent {
    name: string;
}

function emitCounter(
    setState: React.Dispatch<React.SetStateAction<AppState>>,
    event: CounterEvent,
) {
    setState((prevState: AppState) => {
        const counters = prevState.counters;
        if (!counters[event.name]) {
            counters[event.name] = 0;
        }
        counters[event.name]++;
        countersStorage.set(counters);

        return {
            ...prevState,
            ...{
                counters: counters,
            },
        };
    });
}

function emitCounterClear(
    setState: React.Dispatch<React.SetStateAction<AppState>>,
    event: CounterEvent,
) {
    setState((prevState: AppState) => {
        const counters = prevState.counters;
        delete counters[event.name];
        countersStorage.set(counters);

        return {
            ...prevState,
            ...{
                counters: counters,
            },
        };
    });
}
