import React, {useState} from 'react';
import block from 'bem-cn-lite';
import EnginePng from '@assets/engine.png';
import LogoPng from '@assets/logo.png';
import BannedPng from '@assets/banner.png';
import {Col, Grid, Row} from '@gravity-ui/page-constructor';
import {ContactForm} from './ContactForm';
import {Button} from '@components/shared/button';
import {FooterBlock} from './FooterBlock';
import {useNavigate} from 'react-router-dom';

import './IndexPage.scss';

const b = block('index-page');

export const IndexPage: React.FC = () => {
    const navigateTo = useNavigate();
    const [showForm, setShowForm] = useState(false);

    return (
        <>
            <Grid containerClass={b('container')} className={b()}>
                <Row className={b('head')}>
                    <Col sizes={{sm: 1}}></Col>
                    <Col sizes={{sm: 9}}>
                        <img src={LogoPng} alt="Авто-Транзит" />
                    </Col>
                    <Col sizes={{sm: 1}} className={b('contact')}>
                        <Button size={'xl'} onClick={() => setShowForm(true)} design="gray">
                            Связаться
                        </Button>
                    </Col>
                    <Col sizes={{sm: 1}}></Col>
                </Row>
                <Row className={b('content')}>
                    <Col sizes={{sm: 1}}></Col>
                    <Col sizes={{sm: 5}}>
                        <p>
                            Автозапчасти оптом
                            <br />
                            напрямую
                            <br />
                            от поставщиков
                        </p>
                        <Button size="xl" onClick={() => navigateTo('/login')}>
                            Войти в личный кабинет
                        </Button>
                    </Col>
                    <Col sizes={{sm: 5}}>
                        <img src={EnginePng} width={441} alt={'engine'} />
                    </Col>
                    <Col sizes={{sm: 1}}></Col>
                </Row>
                <Row className={b('banner')}>
                    <Col sizes={{sm: 1}}></Col>
                    <Col sizes={{sm: 10}} className={b('banner-col')}>
                        <img src={BannedPng} width="100%" alt={'banner'} />
                    </Col>
                    <Col sizes={{sm: 1}}></Col>
                </Row>
                <Row className={b('footer')}>
                    <Col sizes={{sm: 1}}></Col>
                    <Col sizes={{sm: 10}} className={b('footer-content')}>
                        <FooterBlock />
                    </Col>
                    <Col sizes={{sm: 1}}></Col>
                </Row>
            </Grid>
            <ContactForm open={showForm} setOpen={setShowForm} />
        </>
    );
};
