import React from 'react';
import block from 'bem-cn-lite';
import './Header.scss';
import {Text} from '@gravity-ui/uikit';

const b = block('header');

function BasketControls() {
    return null;
}

export const Header: React.FC = () => {
    return (
        <div className={b()}>
            <div className={b('block', b('is-selected'))} />
            <div className={b('block', b('date'))}><Text variant={'subheader-2'}>Дата</Text></div>
            <div className={b('block', b('article'))}><Text variant={'subheader-2'}>Бренд/Артикул</Text></div>
            <div className={b('block', b('icons'))}></div>
            <div className={b('block', b('rating'))}><Text variant={'subheader-2'}>Рейтинг</Text></div>
            <div className={b('block', b('quantity'))}><Text variant={'subheader-2'}>Наличие</Text></div>
            <div className={b('block', b('delivery-days'))}><Text variant={'subheader-2'}>Срок, дн</Text></div>
            <div className={b('block', b('delivery-date'))}><Text variant={'subheader-2'}>К дате</Text></div>
            <div className={b('block', b('price'))}><Text variant={'subheader-2'}>Цена</Text></div>
            <div className={b('block', b('amount'))}><Text variant={'subheader-2'}>Сумма</Text></div>
            <div className={b('block', b('quantity-counter'))} />
            <div className={b('block', b('aux-controls'))} />
        </div>
    );
};