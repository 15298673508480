import React, {useEffect, useState} from 'react';
import block from 'bem-cn-lite';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {ChevronDown} from '@gravity-ui/icons';
import {SearchBar} from '@features/search';
import {useSearchHistory} from '@features/search/hooks';
import {SearchInputModel, SearchRequest, SuggestItem} from '@features/search/interfaces';
import './HomePage.scss';
import {CApi as userGwApi} from '@services/api';

const b = block('home-page');

export const HomePage: React.FC = () => {
    const [searchParams] = useSearchParams();
    const [searchText, setSearchText] = useState<SearchInputModel>({
        originalValue: searchParams.get('text') || '',
        cleanedValue: searchParams.get('text') || ''
    });
    const [searchBrand, setSearchBrand] = useState(''); // setSearchBrand
    const [useAnalogs, setUseAnalogs] = useState(!(searchParams.get('useAnalogs') === 'false'));

    const [suggestItems, setSuggestItems] = useState<SuggestItem[]>([]);
    const [showBrandSuggestions, setShowBrandSuggestions] = useState(false);
    const [searchHistory] = useSearchHistory();

    // TODO Сделал быстрый хак, чтобы показать список брендов при открытии страницы, надо переделать по-другому или хотя бы вынести работу с подсказками в отдельный сервис
    const [doFirstSearch, setDoFirstSearch] = useState(!!searchParams.get('text'));
    useEffect(() => {
        if (doFirstSearch) {
            userGwApi()
                .suggestBrand.suggestBrandList({
                    article: searchText.cleanedValue,
                })
                .then((r) => {
                    setSuggestItems(r.data.items);
                    setShowBrandSuggestions(r.data.items.length > 0);
                })
                .catch((err) => {
                    // eslint-disable-next-line no-console
                    console.log(err);
                });
            setDoFirstSearch(false);
        }
    }, [doFirstSearch]);

    const navigateTo = useNavigate();

    function startSearch() {
        if (searchText?.cleanedValue && searchBrand)
            navigateTo(
                `/search?text=${searchText.cleanedValue}&brand=${searchBrand}&useAnalogs=${useAnalogs}`,
            );
        else if (searchText?.cleanedValue) showBrands();
    }

    function showBrands() {
        setShowBrandSuggestions(true);
    }

    function handleInputChange(value: SearchInputModel) {
        setSearchBrand('');
        setSearchText(value);
    }

    function handleSearchClick() {
        startSearch();
    }

    function handleSearchHistorySelected(searchHistory: SearchRequest) {
        setSearchBrand(searchHistory.brand);
        setSearchText({
            originalValue: searchHistory.article,
            cleanedValue: searchHistory.article,
        });
    }

    useEffect(() => {
        if (searchBrand && searchText) startSearch();
    }, [searchBrand, useAnalogs]);

    return (
        <div className={b()}>
            <div className={b('search-panel')}>
                <SearchBar
                    size="l"
                    searchText={searchText}
                    searchBrand={searchBrand}
                    useAnalogs={useAnalogs}
                    onSearchClick={handleSearchClick}
                    onInputChange={handleInputChange}
                    onBrandSelected={(brand) => setSearchBrand(brand.name)}
                    onUseAnalogsChanged={(value) => setUseAnalogs(value)}
                    onBrandSuggestionsChanged={(items) => setSuggestItems(items)}
                    searchHistory={searchHistory}
                    onSearchHistorySelected={handleSearchHistorySelected}
                />
                {showBrandSuggestions ? (
                    <div className={b('brand-suggestions')}>
                        {/* SectionHeader */}
                        <div className={b('brand-suggestions-header')}>
                            {'Результаты поиска бренда'}
                            <ChevronDown />
                        </div>
                        {/* SectionItems */}
                        <div className={b('brand-suggestions-items')}>
                            {/* SectionItemsHeader */}
                            <div className={b('brand-suggestions-header2')}>
                                <div>Бренд</div>
                                <div>Артикул</div>
                                <div>Наименование</div>
                            </div>
                            {/* Item */}
                            {suggestItems.map((x) => {
                                return (
                                    <div
                                        key={x.name}
                                        className={b('brand-suggestions-item')}
                                        onClick={() => setSearchBrand(x.name)}
                                    >
                                        <div>{x.name}</div>
                                        <div>
                                            <span className={b('brand-suggestions-article')}>
                                                {searchText.cleanedValue}
                                            </span>
                                        </div>
                                        <div>{x.articleName}</div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};
