import React from 'react';
import block from 'bem-cn-lite';
import {Modal, Text, TextArea, TextInput} from '@gravity-ui/uikit';
import {useForm} from 'react-hook-form';
import {Button} from '@components/shared/button';
import {CApi} from '@services/api';

import './ContactForm.scss';

const b = block('contact-form');

interface ContactFormProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

interface ContactFormForm {
    name: string;
    phone: string;
    email: string;
    message?: string;
}

export const ContactForm: React.FC<ContactFormProps> = ({open, setOpen}) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues,
        setValue,
    } = useForm<ContactFormForm>({defaultValues: {name: "", phone: "+7 (", email: ""}})

    const onSubmit = () => {
        setOpen(false)
        CApi().contactUs.contactUsCreate(
            getValues(),
        )
    }

    return (
        <Modal open={open} onClose={() => setOpen(false)} className={b()}>
            <Text variant="header-2">Связаться с нами</Text>
            <Text style={{
                fontWeight: 400,
                fontSize: '15px',
                lineHeight: '20px',
            }}>Оставьте контакты и мы свяжемся с вами в рабочее время</Text>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <div className={b('input')}>
                    <Text variant="body-2">Имя Фамилия<sup>*</sup></Text>
                    <TextInput
                        size="xl"
                        {...register("name", {
                            required: 'Обязательно',
                        })}
                        errorMessage={errors.name && errors.name.message}
                        errorPlacement="outside"
                        validationState={errors.name && 'invalid'}
                    />
                </div>
                <div className={b('input')}>
                    <Text variant="body-2">Телефон<sup>*</sup></Text>
                    <TextInput
                        size="xl"
                        type="tel"
                        {...register("phone", {
                            required: 'Обязательно',
                            pattern: {
                                value: new RegExp('^(\\+7)(\\s\\(\\d{3}\\)\\s\\d{3}-\\d{2}-\\d{2})$'),
                                message: 'Некорректный номер телефона',
                            },
                            onChange: () => {
                                let clearInput = getValues("phone").replaceAll(new RegExp('[^\\d]', 'g'), '')

                                if (!clearInput.startsWith("7")) {
                                    clearInput = "7" + clearInput
                                }

                                if (clearInput.length > 11) {
                                    clearInput = clearInput.substring(0, 11)
                                }

                                const viewRegex = new RegExp('7(?<code>\\d{0,3})?(?<p1>\\d{0,3})?(?<p2>\\d{0,2})?(?<p3>\\d{0,2})?')
                                const matches = clearInput.match(viewRegex)

                                let resultVal = '+7 (';
                                if (matches) {
                                    if (matches.groups?.code) {
                                        resultVal += "$1"
                                    }

                                    if (matches.groups?.p1) {
                                        resultVal += ") $2"
                                    }

                                    if (matches.groups?.p2) {
                                        resultVal += "-$3"
                                    }

                                    if (matches.groups?.p3) {
                                        resultVal += "-$4"
                                    }
                                }

                                setValue("phone", clearInput.replace(viewRegex, resultVal))
                            }
                        })}
                        errorMessage={errors.phone && errors.phone.message}
                        errorPlacement="outside"
                        validationState={errors.phone && 'invalid'}
                    />
                </div>
                <div className={b('input')}>
                    <Text variant="body-2">Почта<sup>*</sup></Text>
                    <TextInput
                        size="xl"
                        type="email"
                        {...register("email", {
                            required: 'Обязательно',
                            pattern: {
                                value: new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$'),
                                message: 'Некорректный email',
                            }
                        })}
                       errorMessage={errors.email && errors.email.message}
                       errorPlacement="outside"
                       validationState={errors.email && 'invalid'}
                    />
                </div>
                <div className={b('input')}>
                    <Text variant="body-2">Сообщение</Text>
                    <TextArea size="xl" placeholder="Необязательно" rows={3} {...register("message")}></TextArea>
                </div>
                <div className={b('input')}>
                    <Button type="submit" size="xl">Отправить</Button>
                </div>
            </form>
        </Modal>
    );
};
