import React from 'react';
import block from 'bem-cn-lite';
import './QuantityInput.scss';
import {Button, TextInput} from '@gravity-ui/uikit';
import {Minus, Plus} from '@gravity-ui/icons';

const b = block('quantity-input');

export interface QuantityInputProps {
    className?: string;
    value: number;
}

export const QuantityInput: React.FC<QuantityInputProps> = ({className, value}) => {
    return (
        <div className={b(null, className)}>
            <div className={b('container')}>
                <Button size="m" className={b('button')}>
                    <Minus />
                </Button>
                <TextInput
                    size="s"
                    view="clear"
                    className={b('input')}
                    value={value.toString()}
                ></TextInput>
                <Button size="m" className={b('button')}>
                    <Plus />
                </Button>
            </div>
        </div>
    );
};