import React from 'react';
import block from 'bem-cn-lite';
import './BasketControls.scss';
import {Button} from '@gravity-ui/uikit';
import {ShoppingCart} from '@gravity-ui/icons';
import {QuantityInput} from '@components';

const b = block('basket-controls');

export interface BasketControlsProps {
    value: number;
    className: string;
}

export const BasketControls: React.FC<BasketControlsProps> = ({className, value}) => {
    return (
        <div className={b(null, className)}>
            <QuantityInput value={value} />
            <Button size="m" className={b('button', 'basket-button')} view="action">
                <ShoppingCart />
            </Button>
        </div>
    );
};
