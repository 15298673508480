import React from 'react';
import block from 'bem-cn-lite';
import './ArticleInfo.scss';
import {ArticleInfoModel} from '.';
import {Text} from '@gravity-ui/uikit';

const b = block('article-info');

interface ArticleInfoProps {
    className?: string;
    header: ArticleInfoModel;
}

export const ArticleInfo: React.FC<ArticleInfoProps> = ({className, header}) => {
    return (
        <div className={b('item-header', className)}>
            <Text variant={'body-2'} className={b('brand-name')}>{header.brandName}</Text>
            <Text variant={'body-2'} className={b('article')}>{header.article}</Text>
            <div className={b('break')}></div>
            <Text variant={'body-2'} className={b('article-name')}>{header.articleName}</Text>
        </div>
    );
};