import {Dispatch, SetStateAction, useEffect, useRef, useState} from 'react';
import {CApi as userGwApi} from '@services/api';
import {
    AsyncSearchModel,
    AsyncSearchState,
    FiltersModel,
    SearchInputModel,
    SearchModel,
    SearchRequest,
    SearchState,
    SortOption,
} from '@features/search/interfaces';
import {
    getDefaultSortOptions,
    getFilteredAndSorted,
    isEmpty,
    processSearchApiResults,
} from '@features/search/services';
import {useSearchHistory} from '@features/search/hooks/useSearchHistory';
import {Proposal} from '@services/user-gw';

interface SearchParams {
    searchText: SearchInputModel;
    setSearchText: Dispatch<SetStateAction<SearchInputModel>>;
    searchBrand: string;
    useAnalogs: boolean;
}

export function useSearchResults({
    searchBrand,
    searchText,
    useAnalogs,
    setSearchText,
}: SearchParams): [
    SearchModel,
    SearchState,
    SortOption[],
    FiltersModel,
    boolean,
    SearchRequest[],
    AsyncSearchModel,
    (filters: FiltersModel) => void,
    (sortOption: SortOption) => void
] {
    const [results, setResults] = useState<SearchModel>({analogs: [], searched: [], original: []});
    const [filters, setFilters] = useState<FiltersModel>({
        brands: [],
        deliveryDayRange: {},
        priceRange: {},
        quantityRange: {},
        ratingRange: {},
        isOfficialDealer: false,
        isReturnPossible: false,
        priceLevel: false,
    } as FiltersModel);
    const [sortOptions, setSortOptions] = useState<SortOption[]>(getDefaultSortOptions());
    const [searchState, setSearchState] = useState<SearchState>(SearchState.None);
    const [showClearFilters, setShowClearFilters] = useState<boolean>(false);
    const [searchHistory, setSearchHistory] = useSearchHistory();
    const [asyncSearchModel, setAsyncSearchModel] = useState<AsyncSearchModel>({
        showResults: showAsyncGen2Results,
        state: AsyncSearchState.None,
        length: 0
    });

    const searchRequest = useRef<SearchRequest>({
        brand: '',
        article: ''
    });
    const apiResults = useRef<Proposal[]>([]);
    const apiAsyncGeneration2Results = useRef<Proposal[]>([]);
    const sourceResults = useRef<SearchModel>({
        searched: [],
        original: [],
        analogs: [],
    });
    const asyncKey = useRef<string>();

    function resetGeneration(results: SearchModel) {
        results.searched.forEach(x => x.proposals.forEach(p => p.generation = 0));
        results.original.forEach(x => x.proposals.forEach(p => p.generation = 0));
        results.analogs.forEach(x => x.proposals.forEach(p => p.generation = 0));
    }

    function scheduleGeneration1Query(asyncKey: string, searchRequest: SearchRequest) {
        const generationTimeout = 1000;

        setAsyncSearchModel((prev) => {
            return {
                ...prev,
                state: AsyncSearchState.Searching
            };
        });

        setTimeout(() => {
            userGwApi().suggest
                .asyncProposalsDetail(asyncKey)
                .then((r) => {
                    console.log(`async query results: ${r.data.items.length}`);

                    const [results, filters] = processSearchApiResults({
                        searchResults: [apiResults.current, r.data.items],
                        searchRequest
                    });
                    apiResults.current = [...apiResults.current, ...r.data.items];
                    sourceResults.current = results;
                    const filteredAndSorted = getFilteredAndSorted(results, filters, sortOptions);

                    setFilters(filters);
                    setResults(filteredAndSorted);

                    if (r.data.state === 'in_progress')
                        scheduleGeneration2Query(asyncKey, searchRequest);
                    else
                        setAsyncSearchModel((prev) => {
                            return {...prev, state: AsyncSearchState.None};
                        });
                })
            ;
        }, generationTimeout);
    }

    function scheduleGeneration2Query(asyncKey: string, searchRequest: SearchRequest) {
        const generationTimeout = 9000;

        setTimeout(() => {
            userGwApi().suggest
                .asyncProposalsDetail(asyncKey)
                .then((r) => {
                    console.log(`async query results: ${r.data.items.length}`);

                    apiAsyncGeneration2Results.current = r.data.items;
                    setAsyncSearchModel((prev) => {
                        return {
                            ...prev,
                            state: AsyncSearchState.None,
                            length: r.data.items.length
                        };
                    });
                })
            ;
        }, generationTimeout);
    }

    function showAsyncGen2Results() {
        const [results, filters] = processSearchApiResults({
            searchResults: [apiResults.current, apiAsyncGeneration2Results.current],
            searchRequest: searchRequest.current,
        });
        apiResults.current = [...apiResults.current, ...apiAsyncGeneration2Results.current];
        sourceResults.current = results;
        const filteredAndSorted = getFilteredAndSorted(results, filters, sortOptions);

        setFilters(filters);
        setResults(filteredAndSorted);
        setAsyncSearchModel((prev) => {
            return {...prev, length: 0};
        })
    }

    useEffect(() => {
        if (!searchBrand) return;

        let ignore = false;

        searchRequest.current = {
            article: searchText.cleanedValue,
            brand: searchBrand,
        } as SearchRequest;

        setSearchState(SearchState.Searching);

        const resultsPromise = userGwApi().suggest.suggestCreate({
            ...searchRequest.current,
            useAnalogs: useAnalogs,
        });

        resultsPromise
            .then((r) => {
                if (!ignore) {
                    apiResults.current = r.data.items;

                    const [results, filters] = processSearchApiResults({
                        searchResults: [r.data.items],
                        searchRequest: searchRequest.current,
                    });
                    sourceResults.current = results;
                    asyncKey.current = r.data.asyncKey;

                    const isEmptyAfterFetch = isEmpty(results);
                    const filteredAndSorted = getFilteredAndSorted(results, filters, sortOptions);
                    setFilters(filters);
                    setResults(filteredAndSorted);
                    setSearchState(isEmptyAfterFetch ? SearchState.Empty : SearchState.Found);
                    if (!isEmptyAfterFetch)
                        setSearchText((prev) => {
                            return {
                                cleanedValue: prev.cleanedValue,
                                originalValue: prev.cleanedValue,
                            };
                        });

                    if (asyncKey.current)
                        scheduleGeneration1Query(asyncKey.current, searchRequest.current);
                }
            })
            .catch((e) => {
                console.log(e);
                // clearResults();
            })
            .finally(() => addToSearchHistory(searchRequest.current));

        return () => {
            ignore = true;
        };

    }, [searchBrand, useAnalogs]);

    function handleFiltersChanged(filters: FiltersModel) {
        resetGeneration(sourceResults.current);
        const filteredAndSortedResults = getFilteredAndSorted(sourceResults.current, filters, sortOptions);
        const isEmptyAfterFilters = isEmpty(filteredAndSortedResults);
        setFilters(filters);
        setResults(filteredAndSortedResults);
        setSearchState(isEmptyAfterFilters ? SearchState.Empty : SearchState.Found);
        setShowClearFilters(!isEmpty(sourceResults.current));
    }

    function handleSortOptionChanged(sortOption: SortOption) {
        resetGeneration(sourceResults.current);
        const newSortOptions = sortOptions.map((x) => {
            return x.property !== sortOption.property
                ? {...x, direction: 'asc', isActive: false}
                : {...x, direction: sortOption.direction, isActive: true};
        }) as SortOption[];
        const filteredAndSortedResults = getFilteredAndSorted(sourceResults.current, filters, newSortOptions);
        setSortOptions(newSortOptions);
        setResults(filteredAndSortedResults);
    }

    function addToSearchHistory(searchRequest: SearchRequest) {
        setSearchHistory((prev) => {
            const excludeCurrent = () =>
                prev.filter(
                    (x) => x.article !== searchRequest.article || x.brand !== searchRequest.brand,
                );

            return [searchRequest, ...excludeCurrent().slice(0, 9)];
        });
    }

    return [
        results,
        searchState,
        sortOptions,
        filters,
        showClearFilters,
        searchHistory,
        asyncSearchModel,
        handleFiltersChanged,
        handleSortOptionChanged,
    ];
}