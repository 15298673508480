import React from 'react';
import block from 'bem-cn-lite';
import {ShieldCheck} from '@gravity-ui/icons';
import './OfficialDealerBadge.scss';

const b = block('official-dealer-badge');

export interface OfficialDealerBadgeProps {
    value: boolean;
}

export const OfficialDealerBadge: React.FC<OfficialDealerBadgeProps> = ({value}) => {
    return <div className={b()}>{value ? <ShieldCheck /> : <></>}</div>;
};