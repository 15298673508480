import React from 'react';
import block from 'bem-cn-lite';
import './BasketHeaderControls.scss'
import {Checkbox, Text} from '@gravity-ui/uikit';
import {ClockArrowRotateLeft, TrashBin} from '@gravity-ui/icons';

const b = block('basket-header-controls');

export const BasketHeaderControls: React.FC = () => {
    return <div className={b()}>
        <Checkbox size={'l'} className={b('select-all')}><Text variant={'body-2'}>Выбрать всё</Text></Checkbox>
        <div className={b('delete-selected')}>
            <TrashBin />
            <Text variant={'body-2'}>Удалить выбранное</Text>
        </div>
        <div className={b('check-items')}>
            <ClockArrowRotateLeft />
            <Text variant={'body-2'}>Проверить наличие и цены</Text>
        </div>
    </div>;
};