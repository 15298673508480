import {SuggestResultApi} from '@features/search/interfaces';

export const GetTestData = (useAnalogs: boolean): SuggestResultApi => {
    function getRandomId() {
        return Math.floor(Math.random() * 10000000).toString();
    }

    const detailHyundai491304A400 = {
        article: '491304A400',
        articleName: 'Подшипник с цилиндрическими роликами / В',
        brandName: 'Hyundai / KIA',
        isAnalog: false,
    };
    const detailHyundai491304A401 = {
        brandName: '555',
        article: '491304A401',
        articleName: 'Шариковый подшипник d=52mm / Bearing-CTR',
        isAnalog: true,
    };
    const detailFebestHYBC100 = {
        brandName: 'Febest',
        article: 'HYBC100',
        articleName: 'Подшипник подвесной карданного вала',
        isAnalog: true,
    };
    const abc = {
        brandName: 'ABC',
        article: 'HYBC100',
        articleName: 'Подшипник подвесной карданного вала',
        isAnalog: true,
    };

    const abcd = {
        brandName: 'Toyota',
        article: 'HYBC100',
        articleName: 'Подшипник подвесной карданного вала',
        isAnalog: true,
    };
    const abcde = {
        brandName: 'Nissan / Infinity',
        article: 'HYBC100',
        articleName: 'Подшипник подвесной карданного вала',
        isAnalog: true,
    };

    const abcdef = {
        brandName: 'ВАЗ',
        article: 'HYBC100',
        articleName: 'Подшипник подвесной карданного вала',
        isAnalog: true,
    };


    const other = {
        description: '', // empty
        expiresAt: '',
    };

    const data = {
        total: 100,
        items: [
            {
                ...detailHyundai491304A400,
                ...other,
                id: getRandomId(),
                delivery: {
                    expected: {client: {date: '2024-09-03T00:00:00.0000000Z', days: 6}},
                },
                multiplicity: 1,
                price: 113445,
                quantity: 749,
                rating: 70,
                returnCondition: 'impossible',
            },
            {
                ...detailHyundai491304A400,
                ...other,
                id: getRandomId(),
                delivery: {
                    expected: {client: {date: '2024-09-03T00:00:00.0000000Z', days: 6}},
                },
                multiplicity: 1,
                price: 143897,
                quantity: 311,
                rating: 32,
                returnCondition: '',
            },
            {
                ...detailHyundai491304A400,
                ...other,
                id: getRandomId(),
                delivery: {
                    expected: {client: {date: '2024-09-03T00:00:00.0000000Z', days: 6}},
                },
                multiplicity: 1,
                price: 174890,
                quantity: 998,
                rating: 10,
                returnCondition: '',
            },
            {
                ...detailHyundai491304A400,
                ...other,
                id: getRandomId(),
                delivery: {
                    expected: {client: {date: '2024-09-03T00:00:00.0000000Z', days: 6}},
                },
                multiplicity: 1,
                price: 175890,
                quantity: 55,
                rating: 0,
                returnCondition: '',
            },
            {
                ...detailHyundai491304A400,
                ...other,
                id: getRandomId(),
                delivery: {
                    expected: {client: {date: '2024-09-03T00:00:00.0000000Z', days: 6}},
                },
                multiplicity: 1,
                price: 176000,
                quantity: 114,
                rating: 65,
                returnCondition: '',
            },
            {
                ...detailHyundai491304A400,
                ...other,
                id: getRandomId(),
                delivery: {
                    expected: {client: {date: '2024-09-03T00:00:00.0000000Z', days: 6}},
                },
                multiplicity: 1,
                quantity: 234,
                price: 176999,
                rating: 69,
                returnCondition: '',
            },
            {
                ...detailHyundai491304A400,
                ...other,
                id: getRandomId(),
                delivery: {
                    expected: {client: {date: '2024-09-03T00:00:00.0000000Z', days: 6}},
                },
                multiplicity: 1,
                quantity: 987,
                price: 177000,
                rating: 18,
                returnCondition: '',
            },
            {
                ...detailHyundai491304A400,
                ...other,
                id: getRandomId(),
                delivery: {
                    expected: {client: {date: '2024-09-03T00:00:00.0000000Z', days: 6}},
                },
                multiplicity: 1,
                quantity: 999,
                price: 177110,
                rating: 30,
                returnCondition: '',
            },
            {
                ...detailHyundai491304A400,
                ...other,
                id: getRandomId(),
                delivery: {
                    expected: {client: {date: '2024-09-03T00:00:00.0000000Z', days: 6}},
                },
                multiplicity: 1,
                quantity: 11,
                price: 178100,
                rating: 10,
                returnCondition: '',
            },
            {
                ...detailHyundai491304A401,
                ...other,
                id: getRandomId(),
                delivery: {
                    expected: {client: {date: '2024-09-03T00:00:00.0000000Z', days: 6}},
                },
                multiplicity: 1,
                quantity: 528,
                price: 127678,
                rating: 90,
                returnCondition: '',
            },
            {
                ...detailHyundai491304A401,
                ...other,
                id: getRandomId(),
                delivery: {
                    expected: {client: {date: '2024-09-03T00:00:00.0000000Z', days: 6}},
                },
                multiplicity: 1,
                quantity: 544,
                price: 234789,
                rating: 20,
                returnCondition: '',
            },
            {
                ...detailHyundai491304A401,
                ...other,
                id: getRandomId(),
                delivery: {
                    expected: {client: {date: '2024-09-03T00:00:00.0000000Z', days: 6}},
                },
                multiplicity: 1,
                quantity: 45,
                price: 564793,
                rating: 0,
                returnCondition: 'impossible',
            },
            {
                ...detailFebestHYBC100,
                ...other,
                id: getRandomId(),
                delivery: {
                    expected: {client: {date: '2024-09-03T00:00:00.0000000Z', days: 6}},
                },
                multiplicity: 1,
                quantity: 49,
                price: 156890,
                rating: 100,
                returnCondition: '',
            },
            {
                ...detailFebestHYBC100,
                ...other,
                id: getRandomId(),
                delivery: {
                    expected: {client: {date: '2024-09-03T00:00:00.0000000Z', days: 6}},
                },
                multiplicity: 1,
                quantity: 111,
                price: 143897,
                rating: 30,
                returnCondition: '',
            },
            {
                ...detailFebestHYBC100,
                ...other,
                id: getRandomId(),
                delivery: {
                    expected: {client: {date: '2024-09-03T00:00:00.0000000Z', days: 6}},
                },
                multiplicity: 1,
                quantity: 50,
                price: 474890,
                rating: 10,
                returnCondition: 'impossible',
            },
            {
                ...abc,
                ...other,
                id: getRandomId(),
                delivery: {
                    expected: {client: {date: '2024-09-03T00:00:00.0000000Z', days: 6}},
                },
                multiplicity: 1,
                quantity: 50,
                price: 474890,
                rating: 10,
                returnCondition: 'impossible',
            },
            {
                ...abcd,
                ...other,
                id: getRandomId(),
                delivery: {
                    expected: {client: {date: '2024-09-03T00:00:00.0000000Z', days: 6}},
                },
                multiplicity: 1,
                quantity: 50,
                price: 474890,
                rating: 10,
                returnCondition: 'impossible',
            },
            {
                ...abcde,
                ...other,
                id: getRandomId(),
                delivery: {
                    expected: {client: {date: '2024-09-03T00:00:00.0000000Z', days: 6}},
                },
                multiplicity: 1,
                quantity: 50,
                price: 474890,
                rating: 10,
                returnCondition: 'impossible',
            },
            {
                ...abcdef,
                ...other,
                id: getRandomId(),
                delivery: {
                    expected: {client: {date: '2024-09-03T00:00:00.0000000Z', days: 6}},
                },
                multiplicity: 1,
                quantity: 50,
                price: 147490,
                rating: 0,
                returnCondition: 'impossible',
            },
        ],
    } as SuggestResultApi;

    return useAnalogs
        ? data
        : {
            ...data,
            items: data.items.filter(
                (x) =>
                    x.article === detailHyundai491304A400.article &&
                    x. brandName=== detailHyundai491304A400.brandName,
            ),
        };
};
