import React, {SyntheticEvent, useEffect, useState} from 'react';
import block from 'bem-cn-lite';
import {Button, Checkbox, Text as GText} from '@gravity-ui/uikit';
import {CaretDown, ChevronDown} from '@gravity-ui/icons';

import './BrandFilter.scss';
import {FilterBrandModel, Direction} from '@features/search/interfaces';
import {sortBrandName} from './utils/sortBrandName';
import {formatCurrencyRub} from '@utils';

const b = block('brand-filter');

type Accordion = {
    hiddenBtn: boolean | undefined;
    hiddenContainer: boolean | undefined;
    rotationIcon?: boolean;
}

export interface BrandFilterProps {
    brands: FilterBrandModel[];
    onBrandsChange?: (brands: FilterBrandModel[]) => void;
}

export const BrandFilter: React.FC<BrandFilterProps> = ({brands, onBrandsChange}) => {
    const [sortItems, setSortItems] = useState<FilterBrandModel[]>(brands);
    const [directionSort, setDirectionSort] = useState<Direction>('forward');
    const [activeSort, setActiveSort] = useState<'brands' | 'price' | null>('brands');
    const [accordionBrandList, setAccordionBrandList] = useState<Accordion>({
        hiddenBtn: undefined,
        hiddenContainer: undefined,
        rotationIcon: false,
    });

    useEffect(() => {

        setSortItems(sortBrandName(brands, 'forward', 
            activeSort === 'brands' ? 'brandName': 'minPrice'));

        const isSelectedItem = brands.some(items => items.isSelected);
        if (isSelectedItem) {
            setAccordionBrandList(preState => preState);
        } else {
            if (brands.length > 5 && accordionBrandList.hiddenBtn === undefined) {
                setAccordionBrandList({
                    hiddenBtn: false,
                    hiddenContainer: true,
                });
            }
        }
    }, [brands])

    function handleSortBrand(event: SyntheticEvent) {
        const id = event.currentTarget.id;
        let newDirectionSort = directionSort;
        newDirectionSort = directionSort === 'forward' ? 'reverse' : 'forward';

        if (id === 'brands') {
            setActiveSort('brands');
            setDirectionSort(newDirectionSort);
            setSortItems(sortBrandName(brands, newDirectionSort, 'brandName'));
        }

        if (id === 'price') {
            setActiveSort('price');
            setDirectionSort(newDirectionSort);
            setSortItems(sortBrandName(brands, directionSort, 'minPrice'));
        }
    }

    function handle(brandsParam: FilterBrandModel[]) {
        if (onBrandsChange) onBrandsChange(brandsParam);
    }

    function onBrandSelectedChange(brandName: string, value: boolean) {
        let brandsWithCheck = sortItems.map((brand) => (brand.brandName === brandName ? {...brand, isSelected: value} : brand));
        if (onBrandsChange) {
            onBrandsChange(brandsWithCheck);
        }
    }

    function handleHiddenBrandList() {
        setAccordionBrandList(preState => ({
            ...preState,
            hiddenBtn: preState.hiddenBtn,
            hiddenContainer: !preState.hiddenContainer,
            rotationIcon: !preState.rotationIcon,
        }));
    }

    return (
        <div className={b()}>
            <div className={b('header')}>
                <GText>Бренд</GText>
                <Button id="brands" className={activeSort === 'brands' ? 'sort-btn_active' : ''}
                    size="xs" view="flat" type="button" onClick={handleSortBrand}>
                    <CaretDown />
                </Button>
                <div style={{flexGrow: 1}}></div>
                <GText>Цена</GText>
                <Button id="price" className={activeSort === 'price' ? 'sort-btn_active' : ''}
                    size="xs" view="flat" type="button" onClick={handleSortBrand}>
                    <CaretDown />
                </Button>
            </div>
            <div className={b('brand-list', {hidden: accordionBrandList.hiddenContainer})}>
                {sortItems.map((brand) => {
                    return (
                        <div key={brand.brandName} className={b('brand-row')}>
                            <Checkbox
                                className={b('brand-name')}
                                checked={brand.isSelected}
                                onChange={(e) =>
                                    // handle(onBrandSelectedChange(brand.code, e.target.checked))
                                    onBrandSelectedChange(brand.brandName, e.target.checked)
                                }
                            >
                                {brand.brandName}
                            </Checkbox>
                            <GText className={b('brand-min-price')}>от&nbsp;{formatCurrencyRub(brand.minPrice)}</GText>
                        </div>
                    );
                })
                }
            </div>
            <div className={b('footer', {hidden: accordionBrandList.hiddenBtn})}>
                <GText>Показать все</GText>
                <Button size="xs" view="flat" onClick={handleHiddenBrandList}>
                    <ChevronDown className={b('icon', {rotation: accordionBrandList.rotationIcon})}></ChevronDown>
                </Button>
            </div>
        </div>
    );
};