import {FilterBrandModel, Direction} from '@features/search/interfaces'

export function sortBrandName(items: FilterBrandModel[], direction: Direction, key: keyof FilterBrandModel) {
    return items.slice().sort((a, b) => {
        const valueA = a[key];
        const valueB = b[key];

        if (typeof valueA === 'string' && typeof valueB === 'string') {
            return direction === 'forward' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
        }

        if (typeof valueA === 'number' && typeof valueB === 'number') {
            return direction === 'forward' ? valueA - valueB : valueB - valueA;
        }

        return 0;
    });
}