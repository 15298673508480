import React, {useContext, useEffect} from 'react';
import block from 'bem-cn-lite';
import {ChevronDown} from '@gravity-ui/icons';
import './SectionHeader.scss';
import {Button, Progress, useToaster} from '@gravity-ui/uikit';
import {SearchContext} from '@features/search/context';
import {AsyncSearchState} from '@features/search/interfaces';

const b = block('section-header');

export interface SectionHeaderProps {
    title: string;
    isHidden?: boolean;
    onToggleVisibility?: () => void;
    isTopLevel: boolean;
}

export const SectionHeader: React.FC<SectionHeaderProps> = ({
    isHidden,
    onToggleVisibility,
    title,
    isTopLevel,
}) => {
    const searchContext = useContext(SearchContext);

    function handleClick() {
        onToggleVisibility?.();
    }

    useEffect(() => {
        // Убираем атрибут style, чтобы использовать ccs-анимацию для прогресс-бара
        document.getElementsByClassName('g-progress__item')[0].removeAttribute('style');
    }, []);

    const {add} = useToaster();

    useEffect(() => {
        if (isTopLevel && (searchContext.length > 0)) {
            add({
                name: 'async_search_completed',
                title: 'Поиск по всем поставщикам завершен',
                theme: 'info',
                content: (null),
                autoHiding: 3000,
                isClosable: true,
            });
        }
    }, [isTopLevel, searchContext.length]);

    return (
        <div className={b()}>
            {title}
            <ChevronDown
                className={b('icon', {rotation: isHidden})}
                onClick={handleClick}
            />
            {isTopLevel && (searchContext.state === AsyncSearchState.Searching) ?
                <div className={b('progress')}>
                    <Progress size={'s'} value={0} loading={true} theme={'warning'}></Progress>
                </div>
                : null}
            {isTopLevel && (searchContext.length > 0) ?
                <div className={b('show-all')}>
                    <Button
                        size={'s'}
                        view={'action'}
                        onClick={searchContext.showResults}
                        className={b('show-all-button')}
                    >
                        Показать все результаты
                    </Button>
                </div>
                : null
            }
        </div>
    );
};
