import React from 'react';
import block from 'bem-cn-lite';
import {ToasterComponent, ToasterProvider} from '@gravity-ui/uikit';
import {Outlet} from 'react-router-dom';

import './AuthWrapper.scss';

const className = block('auth-wrapper');

export const AuthWrapper: React.FC = () => {
    return (
        <ToasterProvider>
            <div className={className()}>
                <div className={className('container')}>
                    <Outlet />
                </div>
            </div>
            <ToasterComponent />
        </ToasterProvider>
    );
};
