import React from 'react';
import block from 'bem-cn-lite';
import {SortSelector} from './components/SortSelector';
import {SortProperty, SortOption} from '@features/search/interfaces';

import './SectionItemsHeader.scss';

const b = block('section-items-header');

export interface SectionItemsHeaderProps {
    showSectionHeader: boolean;
    sortOptions: SortOption[];
    onSortChanged?: (sortOption: SortOption) => void;
}

export const SectionItemsHeader: React.FC<SectionItemsHeaderProps> = ({
    showSectionHeader,
    sortOptions,
    onSortChanged,
}) => {
    function handleSortChanged(sortOption: SortOption) {
        if (onSortChanged) onSortChanged(sortOption);
    }

    return showSectionHeader ? (
        <div className={b()}>
            <div className={b('title')}>Бренд/артикул</div>
            <div className={b('proposal-header')}>
                <div className={b('icons')}></div>
                <SortSelector
                    className={b('rating')}
                    title={'Рейтинг'}
                    sortOption={sortOptions[SortProperty.Rating]}
                    defaultDirection={'desc'}
                    onSortChanged={handleSortChanged}
                />
                <SortSelector
                    className={b('quantity')}
                    title={'Наличие'}
                    sortOption={sortOptions[SortProperty.Quantity]}
                    defaultDirection={'desc'}
                    onSortChanged={handleSortChanged}
                />
                <SortSelector
                    className={b('delivery-days')}
                    title={'Срок, дн'}
                    sortOption={sortOptions[SortProperty.DeliveryDays]}
                    defaultDirection={'asc'}
                    onSortChanged={handleSortChanged}
                />
                <SortSelector
                    className={b('delivery-date')}
                    title={'К дате'}
                    sortOption={sortOptions[SortProperty.DeliveryDate]}
                    defaultDirection={'asc'}
                    onSortChanged={handleSortChanged}
                />
                <SortSelector
                    className={b('price')}
                    title={'Цена'}
                    sortOption={sortOptions[SortProperty.Price]}
                    defaultDirection={'asc'}
                    onSortChanged={handleSortChanged}
                />
                <div className={b('basket-controls')}></div>
            </div>
        </div>
    ) : (
        <div className={b('hidden')}></div>
    );
};
