import React, {useState} from 'react';
import {Loader} from '@gravity-ui/uikit';
import block from 'bem-cn-lite';
import './SearchPage.scss';
import {useNavigate, useSearchParams} from 'react-router-dom';
import StickyBox from 'react-sticky-box';
import {Filters, NotFound, SearchBar, Section} from '@features/search';
import {SearchInputModel, SearchRequest, SearchState} from '@features/search/interfaces';
import {useSearchResults} from '@features/search/hooks';
import {SearchContext} from '@features/search/context';

const b = block('search-page');
const stickyElementsTopOffset = 12;

export const SearchPage: React.FC = () => {
    const [searchParams] = useSearchParams();
    const [searchText, setSearchText] = useState<SearchInputModel>({
        originalValue: searchParams.get('text') || '',
        cleanedValue: searchParams.get('text') || '',
    });
    const [searchBrand, setSearchBrand] = useState(searchParams.get('brand') || ''); // setSearchBrand
    const [useAnalogs, setUseAnalogs] = useState(!(searchParams.get('useAnalogs') === 'false'));
    const [
        results,
        searchState,
        sortOptions,
        filters,
        showClearFilters,
        searchHistory,
        asyncSearchModel,
        handleFiltersChanged,
        handleSortOptionChanged,
    ] = useSearchResults({searchText, searchBrand, useAnalogs, setSearchText});

    const navigateTo = useNavigate();

    function handleInputChange(value: SearchInputModel) {
        setSearchBrand('');
        setSearchText(value);
    }

    function handleSearchClick() {
        if (searchText?.cleanedValue && !searchBrand)
            navigateTo(`/home?text=${searchText.cleanedValue}&useAnalogs=${useAnalogs}`);
    }

    function handleSearchHistorySelected(searchHistory: SearchRequest) {
        setSearchText({
            originalValue: searchHistory.article,
            cleanedValue: searchHistory.article,
        });
        setSearchBrand(searchHistory.brand);
    }

    return (
        <SearchContext.Provider value={asyncSearchModel}>
            <div className={b()}>
                <Filters filters={filters} onFiltersChange={handleFiltersChanged} />
                <div className={b('results-panel')}>
                    <div className={b('header-background')}></div>
                    {/* component SearchPanel(minimized: boolean) */}
                    <StickyBox
                        className={b('search-bar-sticky-box')}
                        offsetTop={stickyElementsTopOffset}
                    >
                        <SearchBar
                            searchText={searchText}
                            searchBrand={searchBrand}
                            useAnalogs={useAnalogs}
                            onSearchClick={handleSearchClick}
                            onInputChange={handleInputChange}
                            onBrandSelected={(brand) => setSearchBrand(brand.name)}
                            onUseAnalogsChanged={(value) => setUseAnalogs(value)}
                            searchHistory={searchHistory}
                            onSearchHistorySelected={handleSearchHistorySelected}
                        />
                    </StickyBox>
                    <div
                        className={b(
                            'results-searching',
                            searchState === SearchState.Searching ? '' : 'hidden',
                        )}
                    >
                        <Loader size="l"></Loader>
                    </div>
                    <div
                        className={b(
                            'results-not-found',
                            searchState === SearchState.Empty ? '' : 'hidden',
                        )}
                    >
                        <NotFound showClearFilters={showClearFilters}></NotFound>
                    </div>
                    <div
                        className={b(
                            'results-found',
                            searchState === SearchState.Found ? '' : 'hidden',
                        )}
                    >
                        <Section
                            title="Результат поиска запчасти"
                            showSectionHeader={true}
                            items={results.searched}
                            sortOptions={sortOptions}
                            onSortChanged={handleSortOptionChanged}
                        ></Section>
                        <Section
                            title="Оригинальные замены"
                            showSectionHeader={false}
                            items={results.original}
                            sortOptions={sortOptions}
                            onSortChanged={handleSortOptionChanged}
                        ></Section>
                        <Section
                            title="Аналоги"
                            showSectionHeader={false}
                            items={results.analogs}
                            sortOptions={sortOptions}
                            onSortChanged={handleSortOptionChanged}
                        ></Section>
                    </div>
                </div>
            </div>
        </SearchContext.Provider>
    );
};
