import React from 'react';
import block from 'bem-cn-lite';
import {ArrowsRotateRightSlash} from '@gravity-ui/icons';
import './ReturnBadge.scss';

const b = block('return-badge');

export interface ReturnBadgeProps {
    value: boolean;
}

export const ReturnBadge: React.FC<ReturnBadgeProps> = ({value}) => {
    return <div className={b()}>{value ? <></> : <ArrowsRotateRightSlash />}</div>;
};
