import {SectionItemModel, SortOption, SortProperty} from '@features/search/interfaces';

export function getSorted(collection: SectionItemModel[], sortOption: (SortOption | null)) {

    if (!sortOption) return collection;

    const newCollection: SectionItemModel[] = [];

    for (const item of collection) {
        const newItem = {...item};
        newItem.proposals.sort((a, b) => {
            if (sortOption.property === SortProperty.Price)
                return sortOption.direction === 'asc' ? a.price - b.price : b.price - a.price;
            else if (sortOption.property === SortProperty.Quantity)
                return sortOption.direction === 'asc'
                    ? a.quantity - b.quantity
                    : b.quantity - a.quantity;
            else if (sortOption.property === SortProperty.Rating)
                return sortOption.direction === 'asc'
                    ? a.rating - b.rating
                    : b.rating - a.rating;
            else
                return sortOption.direction === 'asc'
                    ? a.deliveryDays - b.deliveryDays
                    : b.deliveryDays - a.deliveryDays;
        });
        newCollection.push(newItem);
    }

    return newCollection;
}