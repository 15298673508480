import React, {useState} from 'react';
import block from 'bem-cn-lite';
import {Col, Grid, Row} from '@gravity-ui/page-constructor';
import {Text, Tooltip} from '@gravity-ui/uikit';
import {Envelope, Handset, MapPin} from '@gravity-ui/icons';

import './FooterBlock.scss';

const b = block('footer-block');

interface ContactProps {
    children: React.ReactNode;
    copyText: string;
}

const Contact: React.FC<ContactProps> = ({children, copyText}) => {
    const [openTooltip, setOpenTooltip] = useState(false);

    return (
        <Tooltip
            content="Скопировано"
            placement="top-end"
            disabled={!openTooltip}
            openDelay={0}
            contentClassName={b('tooltip')}
            className={b('tooltip-container')}
        >
            <div
                className={b('copy')}
                onClick={() => {
                    setOpenTooltip(true);
                    navigator.clipboard.writeText(copyText);
                    setTimeout(() => setOpenTooltip(false), 1000);
                }}
            >
                {children}
            </div>
        </Tooltip>
    );
};

export const FooterBlock: React.FC = () => {
    return (
        <Grid className={b()}>
            <Row>
                <Col sizes={{sm: 2}}>
                    <Text as="div" variant="subheader-2">
                        ООО «Авто-Транзит»
                    </Text>
                    <br />
                    <Text as="div" variant="body-short">
                        ИНН 12345678910
                    </Text>
                    <Text as="div" variant="body-short">
                        ОГРН 12345678910
                    </Text>
                </Col>
                <Col sizes={{sm: 4}}></Col>
                <Col sizes={{sm: 2}} className={b('contact-us')}>
                    <Text as="div" variant="subheader-2">
                        Связаться с нами:
                    </Text>
                    <br />
                    <Text as="div" variant="body-short">
                        <Contact copyText="+7 (383) 375-16-62">
                            <Handset /> &nbsp;
                            <span>+7 (383) 375-16-62</span>
                        </Contact>
                    </Text>
                    <Text as="div" variant="body-short">
                        <Contact copyText="+7 (383) 375-16-64">
                            <Handset /> &nbsp;
                            <span>+7 (383) 375-16-64</span>
                        </Contact>
                    </Text>
                </Col>
                <Col sizes={{sm: 1}}></Col>
                <Col sizes={{sm: 3}}>
                    <br />
                    <br />
                    <Text as="div" variant="body-short">
                        <Contact copyText="info@zapnsk.ru">
                            <Envelope /> &nbsp;
                            <span>info@zapnsk.ru</span>
                        </Contact>
                    </Text>
                    <Text as="div" variant="body-short">
                        <Contact copyText="Новосибирск, ул. Оловозаводская, 47">
                            <MapPin /> &nbsp;
                            <span>Новосибирск, ул. Оловозаводская, 47</span>
                        </Contact>
                    </Text>
                </Col>
            </Row>
        </Grid>
    );
};
