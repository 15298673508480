import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {ThemeProvider} from '@gravity-ui/uikit';
import {AuthWrapper, Wrapper} from '@components';
import {AppContext, AppState, loadStateFromLS} from './app/context';
import {createEmitter} from './app/events';
import {Login} from '@components/Login';
import {RemindPass} from '@components/RemindPass';
import {BasketPage, HomePage, IndexPage, SearchPage} from '@pages';

const App = () => {
    const [state, setState] = React.useState<AppState>(loadStateFromLS());

    return (
        <AppContext.Provider value={{ state: state, emit: createEmitter(setState) }}>
            <ThemeProvider theme="light">
                <Routes>
                    <Route path="/" element={<Wrapper />}>
                        <Route path="" index element={<IndexPage />} />
                        <Route path="/login" element={<Login />}></Route>
                        <Route path="/remind-pass" element={<RemindPass />}></Route>
                        <Route path="*"></Route>
                    </Route>
                    <Route path="/home" element={<HomePage />}></Route>
                    <Route element={<AuthWrapper />}>
                        <Route path="/search" element={<SearchPage />}></Route>
                        <Route path="/basket" element={<BasketPage />}></Route>
                    </Route>
                </Routes>
            </ThemeProvider>
        </AppContext.Provider>
    );
};

export default App;
