import React from 'react';
import block from 'bem-cn-lite';
import './BasketSummary.scss';
import {BasketSummaryModel} from '.';
import {Button, Text} from '@gravity-ui/uikit';
import {ArrowShapeTurnUpRight} from '@gravity-ui/icons';
import {formatCurrencyRub} from '@utils';

const b = block('basket-summary');

export interface BasketSummaryProps {
    summary: BasketSummaryModel;
}

export const BasketSummary: React.FC<BasketSummaryProps> = ({summary}) => {
    return (
        <div className={b()}>
            <Text variant={'header-1'}>Сумма к заказу:</Text>
            <div className={b('amounts')}>
                <Text variant={'header-1'}>{formatCurrencyRub(summary.totalAmount)}</Text>
                <Text variant={'body-2'}>{summary.totalQuantity.toString()}&nbsp;шт.</Text>
            </div>
            <Button className={b('checkout')} size={'xl'} view={'action'}>
                <div className={b('checkout-button')}>
                    <Text variant={'body-2'}>Оформить заказ</Text>
                    <ArrowShapeTurnUpRight className={b('checkout-icon')} />
                </div>
            </Button>
        </div>
    );
};