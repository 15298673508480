import {IUser, userFromToken, userStorage} from './user';
import React from 'react';
import {tokenStorage} from './token';
import {EventName} from './events';
import {countersStorage} from './counters';

export interface Context {
    state: AppState;
    emit: (name: EventName, event?: object) => void;
}

export interface AppState {
    user?: IUser;
    token?: string;
    counters: Record<string, number>;
}

export const AppContext = React.createContext<Context>({state: {counters: {}}, emit: (_) => {}});

export const loadStateFromLS = (): AppState => {
    const token = tokenStorage.get();

    if (!token) {
        userStorage.clear();

        return {counters: countersStorage.get()};
    }

    return {
        token: token,
        user: userFromToken(token),
        counters: countersStorage.get(),
    };
};
