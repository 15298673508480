import {GetTestData} from '@data/TestData';
import {BasketItemModel} from '@features/basket/interfaces/BasketItemModel';

export const GetBasketTestData = (): BasketItemModel[] => {
    const proposals = GetTestData(true);
    const p1 = proposals.items[0];
    const p2 = proposals.items[1];
    const p3 = proposals.items[3];

    return [
        {
            id: 1,
            checked: true,
            createdDate: new Date('2024-08-07T09:30:00.0000000+0400'),
            article: p1.article,
            articleName: p1.articleName,
            brandName: p1.brandName,
            brand: p1.brand,
            quantity: 2,
            amount: 2 * p1.price,
            proposal: {
                key: p1.id,
                rating: p1.rating,
                deliveryDate: new Date(Date.parse(p1.delivery?.expected.client.date)),
                quantity: p1.quantity,
                deliveryDays: p1.delivery?.expected.client.days,
                isOfficialDealer: false,
                isReturnPossible: p1.returnCondition !== 'impossible',
                price: p1.price,
                multiplicity: p1.multiplicity,
                generation: 0,
            },
        },
        {
            id: 2,
            checked: false,
            createdDate: new Date('2024-07-27T09:44:00.0000000+0400'),
            article: p2.article,
            articleName: p2.articleName,
            brandName: p2.brandName,
            brand: p2.brand,
            quantity: 444,
            amount: 444 * p2.price,
            proposal: {
                key: p2.id,
                rating: p2.rating,
                deliveryDate: new Date(Date.parse(p2.delivery?.expected.client.date)),
                quantity: p2.quantity,
                deliveryDays: p2.delivery?.expected.client.days,
                isOfficialDealer: false,
                isReturnPossible: p2.returnCondition !== 'impossible',
                price: p2.price,
                multiplicity: p2.multiplicity,
                generation: 0,
            },
        },
        {
            id: 3,
            checked: false,
            createdDate: new Date('2024-07-27T09:44:00.0000000+0400'),
            article: p3.article,
            articleName: p3.articleName,
            brandName: p3.brandName,
            brand: p3.brand,
            quantity: 2,
            amount: 2 * p3.price,
            proposal: {
                key: p3.id,
                rating: p3.rating,
                deliveryDate: new Date(Date.parse(p3.delivery?.expected.client.date)),
                quantity: p3.quantity,
                deliveryDays: p3.delivery?.expected.client.days,
                isOfficialDealer: false,
                isReturnPossible: p3.returnCondition !== 'impossible',
                price: p3.price,
                multiplicity: p3.multiplicity,
                generation: 0,
            },
        },
    ];
};