import React from 'react';
import block from 'bem-cn-lite';
import './Item.scss';
import {BasketControls} from './components';
import {ProposalModel} from '@features/search/interfaces';
import {
    ArticleInfo,
    ArticleInfoModel,
    ProposalBadges,
    DeliveryDate,
    DeliveryDays,
    Price,
    Quantity,
    Rating,
} from '@components';

const b = block('item');

export interface ItemProps {
    // key: string;
    header: ArticleInfoModel;
    proposals: ProposalModel[];
}

export const Item: React.FC<ItemProps> = ({header, proposals}) => {
    return (
        <div className={b()}>
            <ArticleInfo className={b('item-header')} header={header} />
            <div className={b('proposals')}>
                {proposals.map((proposal) => {
                    return (
                        <div key={proposal.key} className={b('proposal', {gen1: proposal.generation > 0})}>
                            <ProposalBadges
                                className={b('icons')}
                                isOfficialDealer={proposal.isOfficialDealer}
                                isReturnPossible={proposal.isReturnPossible}
                            />
                            <Rating className={b('rating')} value={proposal.rating} />
                            <Quantity className={b('quantity')} value={proposal.quantity} />
                            <DeliveryDays
                                className={b('delivery-days')}
                                value={proposal.deliveryDays}
                            />
                            <DeliveryDate
                                className={b('delivery-date')}
                                value={proposal.deliveryDate}
                            />
                            <Price className={b('price')} value={proposal.price} />
                            <BasketControls className={b('basket-controls')} value={1} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
