import React from 'react';
import block from 'bem-cn-lite';
import {Progress} from '@gravity-ui/uikit';
import './Rating.scss';

const b = block('rating');

export interface RatingProps {
    value: number;
    className: string;
}

export const Rating: React.FC<RatingProps> = (props) => {

    const rank = (value: number): string => {
        if (value === 0 || value === null) {
            return '—'
        } else return `${value} %`
    }

    return (
        <div className={b(null, props.className)}>
            <Progress
                className={b('progress')}
                size="m"
                value={props.value}
                text={rank(props.value)}
                colorStops={[
                    { theme: 'danger', stop: 20 },
                    { theme: 'warning', stop: 50 },
                    { theme: 'success', stop: 100 },
                ]}
            ></Progress>
        </div >
    );
};
