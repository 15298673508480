import React from 'react';
import block from 'bem-cn-lite';
import './AuxControls.scss';
import {Button} from '@gravity-ui/uikit';
import {CommentPlus, Magnifier, TrashBin} from '@gravity-ui/icons';

const b = block('aux-controls');

export interface AuxControlsProps {
    className?: string;
}

export const AuxControls: React.FC<AuxControlsProps> = ({className}) => {
    return (
        <div className={b(null, className)}>
            <Button view={'outlined'} className={b('button', b('comment'))}><CommentPlus /></Button>
            <Button view={'outlined'} className={b('button', b('search'))}><Magnifier /></Button>
            <Button view={'outlined'} className={b('button', b('delete'))}><TrashBin /></Button>
        </div>
    );
};