import React, {useEffect} from 'react';
import block from 'bem-cn-lite';
import {Text} from '@gravity-ui/uikit';
import {BasketHeaderControls, BasketItems, BasketSummary, BasketSummaryModel} from '@features/basket';
import './BasketPage.scss';
import {BasketItemModel} from '@features/basket/interfaces';
import {GetBasketTestData} from '@features/basket/data/TestData';

const b = block('basket-page');

export const BasketPage: React.FC = () => {
    const [items, setItems] = React.useState<BasketItemModel[]>([]);
    const [summary, setSummary] = React.useState<BasketSummaryModel>({
        totalAmount: 0,
        totalQuantity: 0,
    });

    useEffect(() => {
        setItems(GetBasketTestData);
    }, []);

    useEffect(() => {
        setSummary({
            totalQuantity: items.reduce((prev, curr) => prev + curr.quantity, 0),
            totalAmount: items.reduce((prev, curr) => prev + curr.amount, 0),
        })
    }, [items]);

    return (
        <div className={b()}>
            <Text variant={'header-2'}>Корзина</Text>
            <BasketHeaderControls />
            <BasketItems items={items} />
            <BasketSummary summary={summary} />
        </div>
    );
};