import React from 'react';
import block from 'bem-cn-lite';
import './Price.scss';
import {Text} from '@gravity-ui/uikit';
import {formatCurrencyRub} from '@utils';

const b = block('price');

export interface PriceProps {
    value: number;
    className: string;
}

export const Price: React.FC<PriceProps> = (props) => {
    return (
        <Text variant={'subheader-2'} className={b() + ' ' + props.className}>
            {formatCurrencyRub(props.value)}
        </Text>
    );
};
