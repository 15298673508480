import React from 'react';
import block from 'bem-cn-lite';
import './Quantity.scss';
import {Text} from '@gravity-ui/uikit';

const b = block('quantity');

export interface QuantityProps {
    value: number;
    className: string;
}

export const Quantity: React.FC<QuantityProps> = (props) => {
    return <Text variant={'body-2'} className={b() + ' ' + props.className}>{props.value}</Text>;
};
