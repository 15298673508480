import React from 'react';
import block from 'bem-cn-lite';
import './Items.scss';
import {BasketItemModel} from '@features/basket/interfaces';
import {
    ArticleInfo,
    DeliveryDate,
    DeliveryDays,
    Price,
    ProposalBadges,
    Quantity,
    QuantityInput,
    Rating,
} from '@components';
import {Checkbox, Text} from '@gravity-ui/uikit';
import {AuxControls} from './components';
import {formatDate} from '@utils';

const b = block('items');

interface ItemsProps {
    items?: BasketItemModel[] | undefined;
}

export const Items: React.FC<ItemsProps> = ({items}) => {
    return (
        <div className={b()}>
            {items?.map(item => {
                const dates = formatDate(item.createdDate, ['dd.mm.yy', 'HH:MM']);

                return (
                    <div key={item.id} className={b('row')}>
                        <div className={b('item', b('is-selected'))}>
                            <Checkbox size={'l'} checked={item.checked}></Checkbox>
                        </div>
                        <div className={b('item', b('date'))}>
                            <Text variant={'body-2'}>
                                {dates[0]}
                            </Text>
                            <Text variant={'body-2'} className={b('date', 'time')}>
                                {dates[1]}
                            </Text>
                        </div>
                        <ArticleInfo
                            className={b('item', b('article'))}
                            header={{
                                article: item.article,
                                articleName: item.articleName,
                                brandName: item.brandName,
                            }}
                        />
                        <ProposalBadges
                            className={b('item', b('icons'))}
                            isOfficialDealer={item.proposal.isOfficialDealer}
                            isReturnPossible={item.proposal.isReturnPossible}
                        />
                        <Rating value={item.proposal.rating} className={b('item', b('rating'))} />
                        <Quantity className={b('item', b('quantity'))} value={item.proposal.quantity} />
                        <DeliveryDays value={item.proposal.deliveryDays} className={b('item', b('delivery-days'))} />
                        <DeliveryDate value={item.proposal.deliveryDate} className={b('item', b('delivery-date'))} />
                        <Price value={item.proposal.price} className={b('item', b('price'))} />
                        <Price value={item.amount} className={b('item', b('amount'))} />
                        <QuantityInput value={item.quantity} className={b('item', b('quantity-input'))} />
                        <AuxControls />
                    </div>
                );
            })}
        </div>
    );
};